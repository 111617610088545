jQuery(window).scroll(function() {
    let position = jQuery(this).scrollTop();
    let href = jQuery('.current-menu-item > a').attr('href');

	let reg = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;
	let pathname = reg.exec(href);
	if (pathname == undefined){
		return;
	}
	if (pathname.length == 2){
		href=pathname[1];
	}
    jQuery('a[id]').each(function() {
        let target = jQuery(this).offset().top;
        let height = jQuery(this).height() + 20;
        if ((position + height) >= target) {
            let id = jQuery(this).attr('id');
            jQuery('.current-menu-item > ul > li >a').removeClass('active');
            jQuery('.current-menu-item > ul > li >a[href="' + href + '#' + id + '"]').addClass('active');
        }
    });
});
